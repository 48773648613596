import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
    currentColor = defaultColor
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
    currentRadius = 'rounded'
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
  }
};


export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error)
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('tappay_current_user') != null
        ? JSON.parse(localStorage.getItem('tappay_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('tappay_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('tappay_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getPasswordPolicy = () => {
  let policy = null;
  try {
    policy =
        localStorage.getItem('tappay_password_policy') != null
            ? localStorage.getItem('tappay_password_policy')
            : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getPasswordPolicy -> error', error);
    policy = null;
  }
  return policy;
};

export const setPasswordPolicy = (policy) => {
  try {
    if (policy) {
      localStorage.setItem('tappay_password_policy', policy);
    } else {
      localStorage.removeItem('tappay_password_policy');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setPasswordPolicy -> error', error);
  }
};


export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getDateWithFormat = (date) => {
  let mm = date.getMonth() + 1; // January is 0!

  const yyyy = date.getFullYear().toString().substring(2, date.getFullYear().toString().length);
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${mm}${yyyy}`;
};
